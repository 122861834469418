<template>
  <div class="type-management-container">
    <vab-query-form>
      <vab-query-form-left-panel :span="12">
        <el-button :icon="Plus" type="primary" @click="handleEdit($event)">
          添加
        </el-button>
        <!-- <el-button :icon="Delete" type="danger" @click="handleDelete($event)">
          批量删除
        </el-button> -->
      </vab-query-form-left-panel>
      <vab-query-form-right-panel :span="12">
        <el-form :inline="true" :model="queryForm" @submit.prevent>
          <el-form-item>
            <el-input v-model.trim="queryForm.likeTitle" clearable placeholder="请输入名称" />
          </el-form-item>
          <el-form-item label="标签">
            <el-select v-model="queryForm.tagIds" clearable>
              <template v-for="(item, index) in tagList" :key="index">
                <el-option :value="item.id" :label="item.tagName"></el-option>
              </template>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-button :icon="Search" type="primary" @click="queryData">
              查询
            </el-button>
          </el-form-item>
        </el-form>
      </vab-query-form-right-panel>
    </vab-query-form>
    <el-table v-loading="listLoading" border :data="list">
      <el-table-column label="id" prop="id" show-overflow-tooltip align="center" />
      <el-table-column label="标题" prop="title" show-overflow-tooltip align="center" />
      <el-table-column label="图片" prop="name">
        <template #default="{ row }">
          <el-image style="width: 100px; height: 100px" :src="row.fileUrl" :preview-src-list="[row.fileUrl]" :initial-index="4" fit="cover" />
        </template>
      </el-table-column>
      <el-table-column label="标签" prop="name">
        <template #default="{ row }">
          <el-tag v-for="(item, index) in row.materialTagVos" :key="index" type='success'>{{item.tagName}}</el-tag>
        </template>
      </el-table-column>
      <el-table-column label="操作" show-overflow-tooltip width="185" align="center">
        <template #default="{ row }">
          <el-button type="primary" @click="handleEdit(row)">
            <vab-icon icon="edit-2-line" />
            编辑
          </el-button>
          <el-button type="danger" @click="handleDelete(row)">
            <vab-icon icon="delete-bin-6-line" />
            删除
          </el-button>
        </template>
      </el-table-column>
      <template #empty>
        <!-- <el-image
                class="vab-data-empty"
                :src="require('@/assets/empty_images/data_empty.png')"
              /> -->
        <el-empty class="vab-data-empty" description="暂无数据" />
      </template>
    </el-table>
    <el-pagination background :current-page="queryForm.pageNumber" :layout="layout" :page-size="queryForm.pageSize" :total="total" @current-change="handleCurrentChange" @size-change="handleSizeChange" />
    <edit :enterpriseCode="queryForm.enterpriseCode" ref="editRef" @fetch-data="fetchData" :tagList='tagList' />
  </div>
</template>

<script>
import { routesWhiteList } from '@/config'
import {
  defineComponent,
  defineAsyncComponent,
  getCurrentInstance,
  onMounted,
  reactive,
  toRefs,
  onActivated,
} from 'vue'

import { Delete, Plus, Search } from '@element-plus/icons'
import {
  enterprisequeryMaterial,
  enterpriseQueryMaterialTag,
  enterpriseDeleteMaterial,
} from '@/api/content'
import { getUserEnterPriseAccount } from '@/api/user'
export default defineComponent({
  name: 'contentCategory',
  components: {
    Edit: defineAsyncComponent(() => import('./components/imageEdit')),
  },
  setup() {
    const { proxy } = getCurrentInstance()

    const state = reactive({
      queryForm: {
        likeTitle: '',
        pageNum: 1,
        pageSize: 10,
        tagIds: '',
        type: 'IMG',
      },
      querySelectForm: { pageNum: 1, pageSize: 999 },
      total: 0,
      listLoading: false,
      list: [],
      layout: 'prev, pager, next',
      editRef: null,
      enterPriseList: [],
      tagList: [],
    })

    const handleEdit = (row) => {
      if (row.id) {
        state.editRef.showEdit(row)
      } else {
        state.editRef.showEdit()
      }
    }

    const handleDelete = (row) => {
      if (row.id) {
        proxy.$baseConfirm('你确定要删除当前项吗', null, async () => {
          const { msg } = await enterpriseDeleteMaterial(row.id)
          proxy.$baseMessage(`操作成功`, 'success', 'vab-hey-message-success')
          await fetchData()
        })
      }
    }
    const queryData = () => {
      state.queryForm.pageNum = 1
      fetchData()
    }

    const fetchData = async () => {
      state.listLoading = true
      const { data } = await enterprisequeryMaterial(state.queryForm)
      const { data: tagList } = await enterpriseQueryMaterialTag(
        state.querySelectForm
      )
      state.list = data.list
      state.total = data.total

      state.tagList = tagList.list
      state.listLoading = false
    }

    const handleSizeChange = (val) => {
      state.queryForm.pageSize = val
      fetchData()
    }
    const handleCurrentChange = (val) => {
      state.queryForm.pageNum = val
      fetchData()
    }
    const fetchEnterPrise = async () => {
      let userInfoData = JSON.parse(localStorage.getItem('userInfo'))
      console.log(userInfoData)
      let params = {
        tid: userInfoData.tid,
        iamUid: userInfoData.uid,
      }
      const { data: userInfo } = await getUserEnterPriseAccount(params)
      state.enterPriseList = userInfo
      // state.queryForm.enterpriseCode = userInfo[0].enterpriseCode
    }

    onActivated(async () => {
      await fetchEnterPrise()
      await fetchData()
    })

    return {
      ...toRefs(state),
      handleEdit,
      queryData,
      fetchData,
      handleSizeChange,
      handleCurrentChange,
      handleDelete,
      Delete,
      Search,
      Plus,
    }
  },
})
</script>
<style lang="scss" scoped></style>
